<template>
  <div class="app-container">
    <div class="content">
      <div class="title">待更换摄像头</div>
      <div class="detail">
        <div class="left">
          <div>摄像头编号：{{ content.cameraid }}</div>
          <div>摄像头名称：{{ content.cameraNo }}</div>
          <div>Mac-ID：{{ content.macId }}</div>
        </div>
        <div class="right">
          <img class="hand" @click="enlargeImg(bkImgUrl)" :src="bkImgUrl" />
        </div>
      </div>

      <el-button class="newDevice" type="primary" @click="getCameraList(true)">扫描新的设备</el-button>
      <div class="title">请选择摄像头进行更换</div>
      <el-input v-model="wordString" class="word-string" placeholder="请输入摄像头MacID进行检索"></el-input>
      <el-table
        :data="nobindtable"
        ref="multipleTable"
        row-key="pkid"
        class="nobindtable"
        @selection-change="handleSelectionChange"
        @select="handleSelection"
        :header-cell-class-name="nobindtableClass"
      >
        <el-table-column type="selection" :selectable="selectInit" width="55" :disable="true"></el-table-column>
        <el-table-column type="index" align="right" :index="noindexTable" min-width="30"></el-table-column>
        <el-table-column prop="macid" align="right" show-overflow-tooltip label="MAC-ID"></el-table-column>
        <el-table-column prop="cameraModel" align="right" show-overflow-tooltip label="摄像头型号"></el-table-column>
        <el-table-column prop="vendor" align="right" show-overflow-tooltip label="摄像头品牌"></el-table-column>
        <el-table-column prop="ip" align="right" show-overflow-tooltip label="IP地址"></el-table-column>
        <el-table-column prop="bindStatus" align="center" label="绑定状态" width="130">
          <template slot-scope="scope">
            <span type="text" size="small">{{ scope.row.bindStatus ? '已绑定' : '未绑定' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="imgUrl" align="center" label="截图">
          <template slot-scope="scope">
            <img :src="scope.row.imgUrl" @click="enlargeImg(scope.row.imgUrl)" class="screenshot" />
          </template>
        </el-table-column>
      </el-table>
      <div class="allSelect clearfix">
        <div class="text fl" v-show="multipleSelection.length">
          已选择摄像头MacID：{{ multipleSelection.length && multipleSelection[0].macid }}
        </div>
        <el-pagination
          class="fr"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
      <el-button @click="back">上一步</el-button>
      <el-button type="primary" @click="bindBox">更换</el-button>
      <el-dialog :visible.sync="imgDialog" class="imgDialog" width="800px" :before-close="handleClose">
        <img :src="bigImgUrl" class="bigImgUrl" />
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { upgrade, getMngInfo } from 'src/api/legacy'

export default {
  data() {
    const { cameraId, productId } = this.$route.query

    return {
      cameraId,
      productId,
      content: {},
      bkImgUrl: '',
      boxId: '',
      bindTableData: [],
      newTable: [],
      multipleSelection: [],
      pkid: '',
      select: false,
      imgDialog: false,
      bigImgUrl: '',
      currentPage: 1,
      pageSize: 5,
      wordString: '',
      total: 0,
    }
  },
  watch: {
    wordString() {
      this.currentPage = 1
      const arrData = this.newTable.filter((e) => e.macid.includes(this.wordString.trim()))
      this.total = arrData.length
    },
  },
  computed: {
    nobindtable() {
      return this.newTable
        .filter((e) => e.macid.includes(this.wordString.trim()))
        .slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
  },
  mounted() {
    this.getMngInfo()
  },
  methods: {
    mysort(a, b) {
      //bindStatus 都为true
      if (a.bindStatus && b.bindStatus) {
        return a.cameraid > b.cameraid ? -1 : 1
      }
      //bindStatus 都为false
      if (!a.bindStatus && !b.bindStatus) {
        return a.cameraid > b.cameraid ? -1 : 1
      }
      //bindStatus 不同时为true
      else {
        return a.bindStatus > b.bindStatus ? 1 : -1
      }
    },
    noindexTable(index) {
      if (typeof index === 'undefined') return
      return index + 1
    },
    nobindtableClass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        return 'hideLabel'
      }
    },
    selectInit(row, index) {
      if (row.bindStatus) {
        return false //不可勾选
      } else {
        return true //可勾选
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    getCameraList(msg) {
      upgrade.getCameraList({ pkid: this.boxId }).then((res) => {
        if (res && res.status === 0) {
          this.newTable = res.data.nobind.sort(this.mysort)
          this.total = res.data.nobind.length
          if (!msg) return
          this.$message({
            message: '扫描成功',
            type: 'success',
          })
        }
      })
    },
    getMngInfo() {
      getMngInfo({ cameraid: this.cameraId }).then((res) => {
        if (res && res.status === 0) {
          const { cameraid, cameraNo, macId } = res.data
          this.bkImgUrl = res.data.bkImgUrl
          this.content = { cameraid, cameraNo, macId }
          this.boxId = res.data.boxId
          this.getCameraList()
        }
      })
    },
    replaceCamera() {
      let data = {
        cameraMacId: this.multipleSelection[0].macid,
        cameraId: this.cameraId,
        productId: this.productId,
      }

      upgrade.replaceCamera(data).then((res) => {
        if (res.status === 0 && res.data) {
          this.$alert('更换成功', '提示', {
            confirmButtonText: '确定',
          })
          this.$router.back()
        }
      })
    },
    handleSelection(selection, row) {
      this.$refs.multipleTable.clearSelection()
      if (selection.length === 0) {
        // 判断selection是否有值存在
        return
      }
      this.$nextTick(() => {
        this.$refs.multipleTable.toggleRowSelection(row, true)
      })
    },
    handleSelectionChange(val) {
      //选中一条
      this.multipleSelection = val
      if (this.multipleSelection.length && this.multipleSelection.length === this.nobindtable.length) {
        this.select = true
      } else {
        this.select = false
      }
    },
    bindBox() {
      if (!this.multipleSelection.length) {
        this.$alert('请先选择更换的摄像头', '更换', {
          confirmButtonText: '确定',
          type: 'warning',
        })
        return
      }
      this.$confirm('是否将原摄像头进更换?', '更换', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.replaceCamera()
      })
    },
    back() {
      this.$router.back()
    },
    next() {
      this.$router.back()
    },
    handleClose(done) {
      done()
    },
    enlargeImg(url) {
      this.imgDialog = true
      this.bigImgUrl = url
    },
  },
}
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}

.containerX .contentX {
  padding-left: 0;
  padding-right: 0;
}

.titletop {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  padding: 0px 20px;
}

.titletop span {
  font-size: 14px;
  color: #5c76cc;
}

.titletop span i {
  color: #333;
}

.content {
  padding: 0 20px 40px;
}
.content .detail {
  padding: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
}
.content .detail .right img {
  height: 100px;
  width: 100px;
  margin-left: 20px;
}
.content .title {
  font-size: 18px;
  margin-top: 20px;
}

.content .title span {
  color: #5c76cc;
}

.bindtable,
.nobindtable,
.allSelect {
  margin-bottom: 20px;
}

/deep/ .nobindtable thead .el-checkbox {
  display: none;
}

.el-button {
  font-size: 12px;
}

.hideLabel .el-checkbox,
.hideLabel div {
  display: none !important;
}
.newDevice {
  margin-top: 20px;
}
.unbind::before {
  content: '';
  height: 5px;
  border-left: 1px solid #409eff;
  margin-right: 12px;
}

.screenshot {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.imgDialog .el-dialog__header {
  padding: 0;
  border: none;
  height: 0px;
}

/* 关闭按钮 */
.imgDialog .el-dialog__headerbtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #c2c2c2;
  right: 10px;
  top: 10px;
}

/* 关闭图标 */
.imgDialog .el-dialog__headerbtn .el-icon-close {
  color: #333;
}

.imgDialog .el-dialog__body {
  padding: 0;
}

.imgDialog .bigImgUrl {
  width: 760px;
  height: 600px;
  vertical-align: top;
}
.el-message-box__content {
  padding-left: 36px;
}

.word-string {
  width: 214px;
}
</style>
